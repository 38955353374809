<template>
    <el-table
        header-row-class-name="custom-table"
        :data="data"
        :show-header="showHeader"
        lazy
        v-if="data.length > 0"
        :class="cnt"
    >
        <el-table-column
            fixed
            width="80"
            v-if="isSr"
            align="center"
        >
            <template #header>
                <span> Sr. # </span>
            </template>
            <template #default="scope">
                <span :class="{ 'table-color-span': true, 
                                'in-active-policy' : typeof scope.row.is_active !== 'undefined' && !scope.row.is_active }" >

                {{ scope.$index + (srs + 1)}}
                </span>
            </template>

        </el-table-column>

        <template
            v-for="list in headers"
            :key="list.label"
        >
            <template v-if="list.inLoopHeader">
                <template v-for="(n, index) in counts" :key="index">
                    <template v-if="index > 0">
                        <el-table-column
                            v-for="(iList, i) in list.headers"
                            :key="i"
                            :fixed="iList.isFixed"
                            :width="iList.width"
                            :align="iList.align"
                        >
                            <template #header>
                                <span v-html="iList.label.replace('CURRENCY', currency).replace('NUMBER_TYPE', generateRefundNTitle(n))" />
                            </template>
                            <template #default="scope">
                                    <div 
                                        class="d-flex"
                                        :class="iList.align == 'center' ? 'justify-content-center' : ''"
                                        v-if="scope.row[list.key].length > 1 && scope.row[list.key][index][iList.value]"
                                    >
                                    
                                    <el-tooltip v-if="iList.isWrap && common.getTextWidth(scope.row[list.key][index][iList.value]) > iList.width" effect="light" open-delay="200" placement="bottom">
                                        <template #content>
                                            <span v-html="scope.row[list.key][index][iList.value]"></span>
                                        </template>
                                        <span
                                            :class="{
                                                'table-color-span': true,
                                                'badge': iList.isBadge && scope.row.is_active, 
                                                'quote-ref-badge': scope.row.is_active && iList.isBadge && iList.value == 'quote_ref_no',
                                                'text-success': iList.isTextSuccess && scope.row.is_completed && scope.row.module == 'policy',
                                                'text-blue': (iList.isMarkFlow && scope.row.is_mark_flow) || scope.row.isTotal,
                                                'wrap-content': iList.isWrap && common.getTextWidth(scope.row[list.key][index][iList.value]) > iList.width,
                                                'in-active-policy': typeof scope.row.is_active !== 'undefined' && !scope.row.is_active,
                                                'cursor-pointer fw-bolder menu-link text-hover-primary text-gray-600': iList.isRoute }"
                                            :style="iList.isWrap ? `max-width: ${iList.width}px !important;` : ''"
                                            v-html="scope.row[list.key][index][iList.value]"
                                        />
                                    </el-tooltip>
                                    <span
                                        v-else
                                            :class="{
                                                'table-color-span': true,
                                                'badge': iList.isBadge && scope.row.is_active, 
                                                'quote-ref-badge': scope.row.is_active && iList.isBadge && iList.value == 'quote_ref_no',
                                                'text-success': iList.isTextSuccess && scope.row.is_completed && scope.row.module == 'policy',
                                                'text-blue': (iList.isMarkFlow && scope.row.is_mark_flow) || scope.row.isTotal,
                                                'in-active-policy': typeof scope.row.is_active !== 'undefined' && !scope.row.is_active,
                                                'color-green': iList.value == 'discrepancy_amount' && parseFloat(scope.row[list.key][index][iList.value]) > 0,
                                                'color-red': iList.value == 'discrepancy_amount' && parseFloat(scope.row[list.key][index][iList.value]) < 0,
                                                'cursor-pointer fw-bolder menu-link text-hover-primary text-gray-600': iList.isRoute
                                                    }"
                                            :style="iList.isWrap ? `max-width: ${iList.width}px !important;` : ''"
                                            v-html="checkAndReplace(scope.row[list.key][index][iList.value])"
                                        />
                                    </div>
                                
                            </template>
                        </el-table-column>
                    </template>
                </template>  
            </template>
            <el-table-column
                v-else
                :fixed="list.isFixed"
                :width="list.width"
                :align="list.align"
            >
                <template #header>
                    <span v-html="list.label.replace('CURRENCY', currency)" />
                </template>
                <template #default="scope">
                        <div 
                            class="d-flex"
                            :class="list.align == 'center' ? 'justify-content-center' : ''"
                        >
                        
                        <el-tooltip v-if="list.isWrap && common.getTextWidth(scope.row[list.value]) > list.width" effect="light" open-delay="200" placement="bottom">
                            <template #content>
                                <span v-html="scope.row[list.value]"></span>
                            </template>
                            <span
                                :class="{
                                    'table-color-span': true,
                                    'badge': list.isBadge && scope.row.is_active, 
                                    'quote-ref-badge': scope.row.is_active && list.isBadge && list.value == 'quote_ref_no',
                                    'text-success': list.isTextSuccess && scope.row.is_completed && scope.row.module == 'policy',
                                    'text-blue': (list.isMarkFlow && scope.row.is_mark_flow) || scope.row.isTotal,
                                    'wrap-content': list.isWrap && common.getTextWidth(scope.row[list.value]) > list.width,
                                    'in-active-policy': typeof scope.row.is_active !== 'undefined' && !scope.row.is_active,
                                    'cursor-pointer fw-bolder menu-link text-hover-primary text-gray-600': list.isRoute }"
                                :style="list.isWrap ? `max-width: ${list.width}px !important;` : ''"
                                v-html="scope.row[list.value]"
                            />
                        </el-tooltip>
                        <span
                            v-else
                                :class="{
                                    'table-color-span': true,
                                    'badge': list.isBadge && scope.row.is_active, 
                                    'quote-ref-badge': scope.row.is_active && list.isBadge && list.value == 'quote_ref_no',
                                    'text-success': list.isTextSuccess && scope.row.is_completed && scope.row.module == 'policy',
                                    'text-blue': (list.isMarkFlow && scope.row.is_mark_flow) || scope.row.isTotal,
                                    'in-active-policy': typeof scope.row.is_active !== 'undefined' && !scope.row.is_active,
                                    'color-green': list.value == 'discrepancy_amount' && parseFloat(scope.row[list.value]) > 0,
                                    'color-red': list.value == 'discrepancy_amount' && parseFloat(scope.row[list.value]) < 0,
                                    'cursor-pointer fw-bolder menu-link text-hover-primary text-gray-600': list.isRoute
                                        }"
                                :style="list.isWrap ? `max-width: ${list.width}px !important;` : ''"
                                v-html="checkAndReplace(scope.row[list.value])"
                            />
                        </div>
                </template>
            </el-table-column>
        </template>
        
    </el-table>
    <el-empty v-else description="No Records Available" />

</template>

<script setup>
import { defineProps, inject } from 'vue'
import { common } from '@/store/stateless/common'

const props = defineProps({
    data: {
        type: Function,
        required: true,
        default: () => []
    },
    headers: {
        type: Function,
        required: true,
        default: () => []
    },
    srs: {
        type: Number,
        required: false,
        default: 0
    },
    isSr: {
        type: Boolean,
        required: false,
        default: false
    },
    counts: {
        type: Number,
        required: false,
        default: 0
    }
})


const { currency, vat } = inject('currency')

function checkAndReplace(data) {
    if(data && typeof data == 'string')
        return data.replace('CURRENCY', currency)

    return data
}

function disabledPopover(data, list) {
    if(list.value == 'quote_ref_no' && !data.relation_quote_ref_no) return true
    if(list.value === 'transaction_type_text' && ![1,5].includes(data.transaction_type)) return true
    if(list.value == 'transaction_type_text' && data.transaction_type == 1 && !data.payment_link) return true
    if(list.value === 'wipLeads') return false
    if(list.isTotalCheck && !data.isTotal) return true

    return false
}

function generateRefundNTitle(val) {
    switch (val) {
        case 1:
          return "";
        case 2:
          return "2nd";
        case 3:
          return "3rd";
        default:
          return val + "st";
      }
}

</script>
